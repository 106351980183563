export function StarFilled() {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.21585 0.017613L3.21602 0L3.21557 0.0210135L3.21568 0.0485375C3.21568 1.88055 1.77601 3.36568 0 3.36568C1.77601 3.36568 3.21568 4.84358 3.21568 6.66667C3.21568 4.86523 4.61459 3.40079 6.35296 3.36632V3.36507C4.61459 3.33043 3.21568 1.85884 3.21568 0.0485375L3.21585 0.017613Z"
        fill="white"
      />
      <path
        d="M10.1266 1.09108C10.1266 1.04478 10.1269 0.998535 10.1276 0.952381H10.1257C10.1263 0.998535 10.1266 1.04478 10.1266 1.09108Z"
        fill="white"
      />
      <path
        d="M10.1266 1.09108C10.1266 6.26331 14.1235 10.4678 19.0902 10.5669V10.5704C14.1235 10.669 10.1266 14.853 10.1266 20C10.1266 14.7912 6.01317 10.5686 0.938956 10.5686C6.01317 10.5686 10.1266 6.32539 10.1266 1.09108Z"
        fill="white"
      />
      <path
        d="M16.8627 13.3819C16.8627 15.1922 18.2616 16.6638 20 16.6984V16.6996C18.2616 16.7342 16.8627 18.1985 16.8627 20C16.8627 18.1769 15.4231 16.699 13.647 16.699C15.4231 16.699 16.8627 15.2139 16.8627 13.3819Z"
        fill="white"
      />
    </svg>
  );
}
