import { z } from 'zod';

export function isOttoEnabled() {
  const { NEXT_PUBLIC_OTTO_ENABLED } = z
    .object({
      NEXT_PUBLIC_OTTO_ENABLED: z
        .string()
        .transform((value) => value === 'true'),
    })
    .parse({
      NEXT_PUBLIC_OTTO_ENABLED: process.env.NEXT_PUBLIC_OTTO_ENABLED,
    });

  return NEXT_PUBLIC_OTTO_ENABLED;
}
