'use client';

import { useCallback, useEffect, useState } from 'react';

import bootstrapTheme from '@/utils/bootstrapTheme';

const useTheme = () => {
  const [theme, setTheme] = useState(
    typeof window === 'undefined' ? 'light' : window.__theme || 'light',
  );

  const toggleTheme = useCallback(() => {
    window?.__setPreferredTheme(theme === 'light' ? 'dark' : 'light');
  }, [theme]);

  useEffect(() => {
    if (!window.__setPreferredTheme) {
      bootstrapTheme();
      setTheme(window.__theme);
    }

    window.__onThemeChange = setTheme;
  }, []);

  return { theme, toggleTheme };
};

export default useTheme;
