import { type Theme } from '@/types/Theme';

const bootstrapTheme = function () {
  window.__onThemeChange = function () {};

  function setTheme(newTheme: Theme) {
    document.documentElement.classList.remove(window.__theme);
    window.__theme = newTheme;
    window.__onThemeChange(newTheme);
    document.documentElement.dataset.theme = newTheme;
    document.documentElement.classList.add(newTheme);
  }

  let preferredTheme;

  try {
    preferredTheme = localStorage.getItem('theme') as Theme;
  } catch (err) {}

  window.__setPreferredTheme = function (newTheme: Theme) {
    setTheme(newTheme);
    try {
      localStorage.setItem('theme', newTheme);
    } catch (err) {}
  };

  const darkQuery = window.matchMedia('(prefers-color-scheme: dark)');
  darkQuery.addEventListener('change', (event: MediaQueryListEvent) => {
    window.__setPreferredTheme(event.matches ? 'dark' : 'light');
  });

  setTheme(preferredTheme || (darkQuery.matches ? 'dark' : 'light'));
};

export default bootstrapTheme;
